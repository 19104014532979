<template>
  <div class="search">

    <Head :centerInfo="true"></Head>
    <div class="search_content mauto">
      <div class="search_inp" style="margin-top: 15px">
        <el-input placeholder="请输入内容" v-model="listQuery.inputVal" class="input-with-select">
          <template #prepend>
            <el-select v-model="select" @change="searchFn" placeholder="请选择">
              <el-option label="作者" value="1"></el-option>
              <el-option label="视频" value="2"></el-option>
            </el-select>
          </template>
          <template #append>
            <el-button @click="searchFn">搜索</el-button>
          </template>
        </el-input>
      </div>
      <div v-if="!userVideoBoxInfo">
          <!--视频-->
          <div class="search_video" v-if="select == 2 && listData.length >0">
            <div class="content_video">
              <div class="info">
                <div class="info_box" v-for="(item,index) in listData" :key="index"
                  @click="jump(item)">
                  <img :src="item.coverUrl" alt="">
                  <p class="videoTitle">{{item.videoTitle}}</p>
                  <div class="info_boxHover">
                    <p>{{item.videoTitle}}</p>
                    <strong>UP 联众官方频道</strong>
                    <em>{{item.playNum}}</em>
                    <span class="iconfont icon-bofang"></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!--用户-->
          <ul class="search_User" v-if="select == 1 && listData.length >0">
            <li v-for="(item,index) in listData" :key="index" @click="seeUserVideo(item)">
              <div class="user_pic" :class="item.username+'xiu'"></div>
              <p class="username">{{item.username}}</p>
              <span>视频数量：{{item.videoCnt}}</span>
            </li>
          </ul>
                <pagination v-show="total>0" :total="total" :page.sync="listQuery.current" :limit.sync="listQuery.size"
        @pagination="searchFn" />
      </div>
 
      <div class="userVideo_box" v-if="userVideoBoxInfo">
          <div class="userDetail"> 
              <div class="user_pic" :class="userVideoQuery.username+'xiu'" ></div>
              <div>
                  <span>{{userVideoQuery.username}}</span>
                  <p>视频数量：{{userVideoQuery.videoCnt}}</p>
              </div>
          </div>
          <div class="userVideo">
                <div class="info">
                  <div class="info_box" v-for="(item,index) in byUserVideoList" :key="index"
                    @click="jump(item)">
                    <img :src="item.coverUrl" alt="">
                    <img class="itemBox_bg" src="~assets/item_bg.png" alt="">
      
                    <p class="videoTitle">{{item.videoTitle}}</p>
                    <div class="info_boxHover" :class="item.active=='true'?'restartTop':''">
                      <p>{{item.videoTitle}}</p>
                      <strong>{{item.name}}</strong>
                      <em>{{item.playNum}}播放</em>
                      <span class="iconfont icon-bofang"></span>
                    </div>
                  </div>
                </div>
          </div>
          <pagination v-show="total>0" :total="total" :page.sync="videoListQuery.current" :limit.sync="videoListQuery.size" @pagination="listVideosByUsernameFn" />
      </div>

      <!--无数据-->
      <el-empty v-if="listData.length == 0 && byUserVideoList.length == 0" image="http://image.ourgame.com/videoWeb/null.png" description=" ">
      </el-empty>
    </div>
  </div>
</template>
<script>
  import Head from '@/components/head.vue';
  import pagination from '@/components/pagination.vue'
  import {
    searchByTitle,
    searchByUsername,
    listVideosByUsername
  } from '@/api/authority'
  export default {
    name: "search",
    components: {
      Head,
      pagination
    },
    data() {
      return {
        input3: '',
        select: '1',
        userVideoBoxInfo:false,
        listQuery: {
          current: 1,
          size: 16,
          inputVal: '',
          username :'',
        },
        videoListQuery: {
          current: 1,
          size: 16,
          username:""
        },
        listData: [],
        userVideoQuery:{
          
        },
        byUserVideoList:[],
        total: 0
      };
    },
    mounted() {
      this.listQuery.inputVal = this.$route.query.vl
      window.scrollTo(0,0)
      if(this.$route.query.type == 2){
        this.seeUserVideo({username:this.$route.query.vl})
      }else{
this.searchFn()
      }
      
    },
    updated() {
      if (this.select == 1) {
        var arr = []
        for (let i in this.listData) {
          arr.push(this.listData[i].username)
        }
        
         this.$store.commit('GET_XIU', {
            name:arr ,
            dom: arr,
            vue: this
          })
      }
    },
    methods: {
      jump(item){
        this.$store.commit('JUMP_PLAY',{alid:item.aliVideoId,videoId:item.videoID,vue:this})
      },
      searchFn(parm) {
        if (this.inputVal != '') {
          if (typeof parm != 'undefined') {
            this.listQuery.current = parm.page
          }
          // this.listQuery.current = 1
          this.userVideoBoxInfo = false
          // 1 作者 2视频
          if (this.select == 2) {
            this.searchByTitleFn()
          } else {
            this.searchByUsernameFn()
          }
        }
      },
      listVideosByUsernameFn(){

        listVideosByUsername(this.videoListQuery).then(response => {
            if (response.data.code == 0) {
              // this.byUserVideoList = response.data.data.records
              // for(let i in 10){
                this.byUserVideoList = []
                for(let i in response.data.data.records){
                    this.byUserVideoList.push(response.data.data.records[i]) 
                }
              // }
              this.total = response.data.data.total
              this.userVideoQuery.videoCnt = response.data.data.total
            } else {
              this.$message({
                'message': response.data.msg
              })
            }
        })
      },
      // 查看作者视频
      seeUserVideo(item){
        this.userVideoBoxInfo = true
        this.userVideoQuery = item
        this.listQuery.current = 1
        this.videoListQuery.username = item.username
        console.log('我出发了')
        this.listQuery.username = item.username
        this.listVideosByUsernameFn()
        console.log(item.username)
        debugger
        this.$store.commit('GET_XIU', {
            name:[item.username] ,
            dom: [item.username],
            vue: this
          })
      },
      // 通过title获取
      searchByTitleFn() {
        this.listQuery.title = this.listQuery.inputVal
        searchByTitle(this.listQuery).then(response => {
          if (response.data.code == 0) {
            this.listData = response.data.data.records
            this.total = response.data.data.total
          } else {
            this.$message({
              'message': response.data.msg
            })
          }
        })
      },
      // 通过名称获取
      searchByUsernameFn() {
        this.listQuery.username = this.listQuery.inputVal
        searchByUsername(this.listQuery).then(response => {
          if (response.data.code == 0) {
            this.listData = response.data.data.records
            this.total = response.data.data.total
          } else {
            this.$message({
              'message': response.data.msg
            })
          }
        })
      }
    }
  }
</script>
<style lang="less">
  .search_content {
    padding-top: 96px;

    .search_video {
      .info .info_box {
        margin: 20px 0px 0px 15px;
      }
    }
.userVideo_box{
  	width: 100%;
    margin-top:40px;
	// height: 1034px;
	background-color: #ffffff;
	border-radius: 9px;
  padding:72px 94px 72px 94px;
}

    .userDetail{
      overflow: hidden;
      .user_pic{
        	width: 95px;
        	height: 95px;
          overflow: hidden;
          margin-right:30px;
             position: relative;
        border-radius: 50%;
        top:0px;
        left:0px;
        >img{
              transform: scale(0.75);
    transform-origin: top left;
        }
      }
      div{
        float:left;
        margin-bottom:50px;
        span{
          color: #ff8a00;
          padding-top:24px;
          display: block;
        }
        p{
          color: #999999;
          padding-top:10px;
          display: block;
        }
      }
    }
    .search_inp {
      width: 451px;
      height: 40px;
      margin: 0px auto;
      border-radius: 19px;
      overflow: hidden;
      border: 0px;

      >.el-input-group {
        >.el-input__inner {
          padding: 0px !important;
        }
      }
    }

    .el-input__inner {
      border-right: 0px !important;
      border-left: 0px !important;
    }

    .el-input__inner:focus {
      border-color: #dcdfe6 !important;
    }

    .el-input__inner:hover {
      border-color: #dcdfe6 !important;
    }

    .el-input-group__prepend {
      width: 40px;
      background: white;
      border-radius: 19px 0px 0px 19px;
    }

    .el-input-group__append {
      border-radius: 0px 19px 19px 0px !important;

      button {
        width: 72px;
        height: 40px;
        background: #ff8a00 !important;
        color: white !important;
        text-align: center;
        border-radius: 0px 19px 19px 0px !important;

        span {
          display: inline-block;
          text-align: center;
        }

        i {

          display: none;
        }
      }
    }

    .search_User {
      margin: -20px 0px 0px -20px;
      clear: both;
      padding-top: 56px;
      cursor: pointer;
      li {
        width: 224px;
        height: 257px;
        background-color: #ffffff;
        border-radius: 9px;
        text-align: center;
        padding-top: 40px;
        box-sizing: border-box;
        font-size: 12px;
        float: left;
        margin: 20px 0px 0px 20px;

        >div {
          width: 95px;
          height: 95px;
          // background-color: #ff5f30;
          overflow: hidden;
          border-radius: 50%;
          margin: 0px auto;
          position: relative;
          top: 0px;
          left: 0px;
          border-radius: 50%;

          img {
            transform: scale(.75);
            transform-origin: top left;
          }
        }

        p,
        span {
          text-align: center;
          padding-top: 20px;
        }

        span {
          padding-top: 14px;
          color: #999999;
          font-size: 12px;
          display: block;
        }
      }
    }
  }
</style>